import { parse, stringify } from 'query-string';
import lodashGet from 'lodash.get';
import { isEnableTemis } from 'components/temis/utils';
import { getSchoolYearOfCurrentUser } from 'components/common/scholastic-config/utils';

/**
 * return the hostname portion of a URL
 * http://abc.com/asdfsdf/asdfsadf => "abc.com"
 * @param url
 * @returns {*}
 */
export const extractHostname = (url) => {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};

export const getFullUrl = (relativeUrl) =>
  `${window.location.protocol}//${window.location.host}${relativeUrl}`;
/**
 * copy from https://stackoverflow.com/questions/11550790/remove-hostname-and-port-from-url-using-regular-expression
 * @param url
 * @return {*}
 */
export const removeHostname = (url, keepBeginningSlash = true) => {
  let newUrl =
    url && url.replace(/^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '$1'); // http or https

  if (newUrl && keepBeginningSlash) {
    newUrl = `/${newUrl}`;
  }

  return newUrl;
};

export const updateUrlSearchString = (search, updateValues = {}) => {
  const urlQueryParams = parse(search);
  return stringify(Object.assign({}, urlQueryParams, updateValues));
};

export const routerUrlParams = (props, paramName, defaultValue = null) => {
  return lodashGet(props, `match.params.${paramName}`, defaultValue);
};

function generateUrlToOtherHost(userInfo, hostUrl, redirectUrl) {
  const { token, iid } = userInfo || {};

  return `${hostUrl}/auth/${token}/${iid}?redirectUrl=${redirectUrl}`;
}

export const generateTEMISUrl = (userInfo = {}, redirectUrl = '/temis') => {
  const temisconfig = window.TEMIS_CONFIG || {};
  if (isEnableTemis()) {
    return '/temis';
  }

  const temisUrl = temisconfig.TEMIS_URL || 'https://temis.csdl.edu.vn';

  return generateUrlToOtherHost(userInfo, temisUrl, redirectUrl);
};

export const generateTaphuanUrl = (userInfo = {}, redirectUrl = '/') => {
  const hostUrl = window.TAPHUAN_URL;

  return generateUrlToOtherHost(userInfo, hostUrl, redirectUrl);
};

export const generateHCMChuyendoisoUrl = (userInfo = {}, redirectUrl = '/') => {
  const hostUrl = window.HCM_CHUYEN_DOI_SO_URL;

  return generateUrlToOtherHost(userInfo, hostUrl, redirectUrl);
};

export const generateTemisAdminCloudContestUrl = (userInfo) => {
  const contestUrl = lodashGet(window, 'adminCloudContestManagementUrl');

  const { token, iid } = userInfo;

  return `${contestUrl}/auth/${token}/${iid}?redirectUrl=/admin/contest`;
};

export const replaceStaticToStatic2 = (url) => {
  if (!url) {
    return url;
  }

  const static2Domain = 'https://taphuan-statics2.csdl.edu.vn';
  const staticHost = 'taphuan-statics.csdl.edu.vn';

  const urlObj = new URL(url);
  const { hostname, pathname } = urlObj;

  if (hostname !== staticHost) {
    return url;
  }

  return `${static2Domain}${pathname}`;
};

export const getBaseAPIUrl = () => {
  // if (isBDTX()) {
  //   return window.APP_SERVER_BDTX_API_URL;
  // }

  return window.APP_SERVER_API_URL;
};

export const generateAdminCloudContestUrl = (userInfo, redirectUrl) => {
  const contestUrl = lodashGet(window, 'adminCloudContestManagementUrl');

  const { token, iid } = userInfo || {};

  return `${contestUrl}/auth/${token}/${iid}?redirectUrl=${redirectUrl ||
    '/admin/contest'}`;
};

export const populateDefaultBdtxParams = (params, schoolYear) => {
  let newParams = params ? { ...params } : {};

  newParams._sand_bdtx = 1;

  schoolYear = schoolYear || getSchoolYearOfCurrentUser();
  if (schoolYear) {
    newParams._sand_school_year = schoolYear;
  }

  return newParams;
};

export const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return !!pattern.test(str);
};

export const getUrlOrigin = (url) => {
  try {
    return new URL(url).origin;
  } catch (e) {
    return null;
  }
};

export const getAllQueryStringParams = (href = '') => {
  if (!href) {
    return {};
  }

  const searchParam = {};

  href.split('#').forEach((path) => {
    const [hash, query] = path.split('?');
    Object.assign(searchParam, Object.fromEntries(new URLSearchParams(query)));
  });

  Object.values(searchParam).forEach((val) => {
    if (val.includes('?')) {
      Object.assign(searchParam, getAllQueryStringParams(val));
    }
  });

  return searchParam;
};
