import './stylesheet.scss';

import React from 'react';
import { textVariants } from 'components/admin/video/schema/blocks/text';
import DisplayHtml from 'components/common/html';
import Image from '../../common/image';
import lodashGet from 'lodash.get';
import Heading from '../../common/Heading';
import {
  getBlockItemImageSize,
  getBlockItemImageStyle,
  getBlockItemSize,
} from '../../common/utils';
import CollaboratingItemComment from 'components/common/comment/collaborating-item-comment';
import { BLOCK_IMAGE_SIZE_FULL } from 'components/admin/video/schema/block-settings/image-size';
import { isSmallScreen } from '../../../../../../common';

const BlockText = ({ item, course, syllabus }) => {
  if (!item) {
    return null;
  }

  const canDiscuss = lodashGet(item, 'show_discussion_box');
  const itemIid = lodashGet(item, 'iid');
  const courseIid = lodashGet(course, 'iid');
  const syllabusIid = lodashGet(syllabus, 'iid');

  const imageFullSize = getBlockItemImageSize(item) === BLOCK_IMAGE_SIZE_FULL;

  let content;
  if (!item.variant || item.variant == textVariants.TEXT_STANDARD) {
    content = (
      <div>
        <Heading item={item} />
        <div className={`content ${imageFullSize ? 'fit-image-content' : ''}`}>
          <DisplayHtml content={item.content} />
        </div>
      </div>
    );
  } else if (item.variant == textVariants.TEXT_IMAGE_TOP) {
    const src = lodashGet(item, 'attachments[0].link');

    content = (
      <div>
        <Heading item={item} />
        <Image src={src} style={getBlockItemImageStyle(item)} />

        <div
          className={`content m-t-30 ${
            imageFullSize ? 'fit-image-content' : ''
          }`}
        >
          <DisplayHtml content={item.content} />
        </div>
      </div>
    );
  } else if (item.variant == textVariants.TEXT_IMAGE_TOP_FULL_WIDTH) {
    const src = lodashGet(item, 'attachments[0].link');

    content = (
      <div>
        <Heading item={item} />
        <Image
          src={src}
          size={getBlockItemSize(item)}
          style={{ maxWidth: '100%', width: '100%' }}
        />
        <div className="content fit-content m-t-30">
          <DisplayHtml content={item.content} />
        </div>
      </div>
    );
  } else if (item.variant == textVariants.TEXT_CHAPO_AVATAR) {
    const src = lodashGet(item, 'attachments[0].link');

    content = (
      <div className="chapo">
        <Heading item={item} />
        <div className={`content ${imageFullSize ? 'fit-image-content' : ''}`}>
          <div className="chapo__image">
            <Image src={src} style={getBlockItemImageStyle(item)} />
          </div>

          <DisplayHtml content={item.content} />
        </div>
      </div>
    );
  } else if (
    item.variant == textVariants.TEXT_RIGHT_AVATAR_LEFT ||
    item.variant == textVariants.TEXT_LEFT_AVATAR_RIGHT
  ) {
    const src = lodashGet(item, 'attachments[0].link');

    const img = (
      <div className="col-md-4 col-xs-12">
        <Image
          src={src}
          style={{
            maxWidth: 'initial',
          }}
        />
      </div>
    );

    const c = (
      <div
        className={`content col-md-8 col-xs-12 ${
          imageFullSize ? 'fit-image-content' : ''
        }`}
      >
        <Heading item={item} />
        <DisplayHtml content={item.content} />
      </div>
    );

    if (item.variant == textVariants.TEXT_RIGHT_AVATAR_LEFT) {
      content = (
        <div className="left-avatar-text-right">
          <div className="row">
            {img} {c}
          </div>
        </div>
      );
    } else {
      content = (
        <div className="left-avatar-text-right">
          <div className="row">
            {c} {img}
          </div>
        </div>
      );
    }
  }

  if (!canDiscuss) {
    return content;
  }

  return (
    <div className="component-discussion">
      <div className="m-b-15">{content}</div>

      <CollaboratingItemComment
        syllabusIid={syllabusIid}
        courseIid={courseIid}
        collaboratingItemId={itemIid}
        collaboratingItem={item}
        isLearn={true}
        askTeacherToAnswer={false}
        classNameCheckBoxForm={`${isSmallScreen ? 'p-l-10' : ''}`}
      />
    </div>
  );
};

export default BlockText;
