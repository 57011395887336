import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutHelper from 'layouts/LayoutHelper';
import apiUrls from 'api-endpoints';
import { getThemeConfig } from 'utils/selectors';
import { t1 } from 'translate';
import Overview from 'components/front-end/common/overview';
import QA from './item';
import LayoutProvider from 'layouts/container';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import {
  isGvOrCbqlTHPT,
  isPGDStaff,
  isSGDStaff,
} from 'components/admin/user/utils';
import Icon from 'components/common/Icon';
import DetailOnDialog from 'components/common/detail-on-dialog';
import CommentAndReflect from 'components/front-end/reflect';
import Tooltip from 'antd/lib/tooltip';
import { isOpenInapp } from 'common/browser';
import { parse } from 'query-string';

const schema = {
  schema: () => ({
    text: {
      type: 'text',
      fullWidth: true,
      hintText: t1('input_text_faq'),
    },
    tags: {
      type: 'hotTags',
      options: 'async',
      floatingLabelText: t1('faq_categories'),
      fullWidth: true,
      paramsasync: {
        __url__: apiUrls.faq_get_hot_tags,
        transformData: (tags) => {
          if (!Array.isArray(tags) || !tags.length) {
            return [];
          }
          return tags.map((tag) => ({
            value: tag,
            label: tag,
          }));
        },
      },
      readOnly: true,
    },
  }),
  ui: () => [
    {
      id: 'default',
      fields: ['text', 'tags'],
    },
  ],
};

const KEY_STATE = 'FAQ';

class Index extends Component {
  overviewStyle = { paddingBottom: '50px' };

  componentDidMount() {
    LayoutHelper.setLayout(this);
  }

  getHeaderData = () => {
    const { themeConfig } = this.props;

    return {
      title: t1('faq'),
      content: t1(
        'find_answers_and_general_information_quickly_about_this_page',
      ),
    };
  };

  renderResultComponent = (items) => {
    if (!Array.isArray(items) || !items.length) {
      return <div>{t1('no_result')}</div>;
    }

    return items.map((item) => <QA key={item.iid} item={item} />);
  };

  render() {
    const { items, userInfo, isLogin } = this.props;
    const header = this.getHeaderData();
    const showReflectionButton =
      !isOpenInapp() &&
      (isGvOrCbqlTHPT(userInfo) ||
        isPGDStaff(userInfo) ||
        isSGDStaff(userInfo));
    const { showCommentAndReflect } = parse(window.location.search);

    if (userInfo && showCommentAndReflect && isOpenInapp()) {
      return (
        <div className="p-b-20 p-l-10 p-r-10">
          <CommentAndReflect userInfo={userInfo} />
        </div>
      );
    }

    return (
      <>
        <LayoutProvider>
          {!isLogin && (
            <Overview
              style={this.overviewStyle}
              title={header.title}
              content={header.content}
              extraElement={
                showReflectionButton && (
                  <DetailOnDialog
                    renderPreview={({ showFull }) => {
                      return (
                        <div style={{ position: 'fixed', top: 115, right: 35 }}>
                          <Tooltip
                            title={t1('title_comment_and_reflect')}
                            className="glowing-flashing"
                          >
                            <Icon
                              icon="reflect"
                              onClick={showFull}
                              className="action"
                              style={{ fontSize: 35, color: '#e72b2b' }}
                            />
                          </Tooltip>
                        </div>
                      );
                    }}
                    renderFull={({ closeDialog }) => {
                      return (
                        <CommentAndReflect
                          requestSuccessful={closeDialog}
                          userInfo={userInfo}
                        />
                      );
                    }}
                    dialogKey="comment_and_reflect"
                    dialogOptionsProperties={{
                      handleClose: true,
                      modal: true,
                      width: '80%',
                      title: t1('title_comment_and_reflect'),
                    }}
                  />
                )
              }
            />
          )}

          <SearchWrapper
            schema={schema}
            hiddenFields={{
              status: ['approved'],
              items_per_page: -1,
            }}
            hidePagination
            showSearchButton={false}
            autoSearchWhenStart
            autoSearchWhenValuesChange
            formid={KEY_STATE}
            renderResultsComponent={this.renderResultComponent}
            alternativeApi={apiUrls.faq_search}
          />
        </LayoutProvider>
      </>
    );
  }
}

const mapPropsToState = (state) => ({
  userInfo: state.user.info,
  screenSize: state.common.screenSize,
  themeConfig: getThemeConfig(state),
});

export default connect(mapPropsToState)(Index);
