import { LayoutRegistered } from 'layouts/LayoutHelper';
import Loadable from 'components/common/async-loader/Loadable';
import RootLayout from 'layouts';
import LHAWithAttendaceRegistering from 'components/admin/session/common/LHAWithAttendaceRegistering';
import {
  adminRootUrl,
  cbqlUrl,
  dashboardUrl,
  guideUrl,
  learnRootUrl,
  lha,
  masterCourseUrl,
  masterUrl,
  profileUrl,
  scheduleRoomUrl,
  systemRootUrl,
  teacherCourseUrl,
  teacherUrl,
} from './root-url';

// frontend
import frontendRoutes from './student/frontend';
import studentDashboardRoutes from './student/student-dashboard';
import learnRoutes from './student/learn';
import schoolSaasRoutes from './school-saas';
import contestRoutes from './student/contest';
import blogRoutes from './student/blog';
import accountRoutes from './student/account';
import temisRoutes from './temis';
import authRoutes from './auth';
import { isEnableTemis } from 'components/temis/utils';
import Loading from '../components/common/loading';
import { meetRoutesConfig } from 'components/front-end/meet/route-registration';

const RoomSchedule = Loadable({
  loader: () => import('components/timetable_v2/viewers/rooms'),
});

const Admin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "admin" */ 'routes/route-groups/admin'),
});

const System = Loadable({
  loader: () =>
    import(/* webpackChunkName: "system" */ 'routes/route-groups/system'),
});

const Dashboard = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard" */ 'routes/route-groups/dashboard'),
});

const Profile = Loadable({
  loader: () =>
    import(/* webpackChunkName: "profile" */ 'routes/route-groups/profile'),
});

const NoMatch = Loadable({
  loader: () =>
    import(/* webpackChunkName: "profile" */ 'components/common/404/index'),
});

const TeacherDashboard = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard" */ 'routes/route-groups/teacher'),
});

const CourseDashboard = Loadable({
  loader: () =>
    import(/* webpackChunkName: "course-dashboard" */ 'routes/route-groups/teacher/course'),
});

const MasterCourseDashboard = Loadable({
  loader: () =>
    import(/* webpackChunkName: "course-dashboard" */ 'routes/route-groups/master/course'),
});

const MasterDashboard = Loadable({
  loader: () =>
    import(/* webpackChunkName: "master-dashboard" */ 'routes/route-groups/master'),
});

const CBQLDashboard = Loadable({
  loader: () =>
    import(/* webpackChunkName: "master-dashboard" */ 'routes/route-groups/cbql'),
});

const LHA = Loadable({
  loader: () => import('components/lha'),
  loading: Loading,
});

const isTemis = isEnableTemis();

const routes = [
  {
    componentId: 'virtual-class',
    path: '/virtual-class',
    component: LHAWithAttendaceRegistering,
    exact: true,
  },
  {
    component: RootLayout,
    routes: isTemis
      ? [...authRoutes(), ...temisRoutes]
      : [
          {
            path: scheduleRoomUrl,
            component: RoomSchedule,
            layout: LayoutRegistered.embedded,
          },
          {
            path: dashboardUrl,
            component: Dashboard,
          },
          {
            path: `${teacherCourseUrl}/:iid`,
            component: CourseDashboard,
          },
          {
            path: `${masterCourseUrl}/:iid`,
            component: MasterCourseDashboard,
          },
          {
            path: teacherUrl,
            component: TeacherDashboard,
          },
          {
            path: masterUrl,
            component: MasterDashboard,
          },
          {
            path: cbqlUrl,
            component: CBQLDashboard,
          },
          {
            path: lha,
            component: LHA,
          },
          {
            path: profileUrl,
            component: Profile,
          },
          /************dashboard stuff*****************/
          ...frontendRoutes(learnRootUrl),
          ...studentDashboardRoutes(),
          ...learnRoutes(),
          ...contestRoutes(),
          ...accountRoutes(),
          ...meetRoutesConfig(),
          // ...testRoutes(learnRootUrl),
          ...blogRoutes(),
          ...schoolSaasRoutes(),
          // ...xpeakRoutes(),
          // ...temisRoutes(),
          ...authRoutes(),
          /************student frontend*****************/
          {
            path: adminRootUrl,
            component: Admin,
            layout: LayoutRegistered.admin,
          },
          {
            path: systemRootUrl,
            component: System,
            layout: LayoutRegistered.admin,
          },
          {
            component: NoMatch,
          },
        ],
  },
];

export default routes;
