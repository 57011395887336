import InApp from 'detect-inapp';
import lodashGet from 'lodash.get';

const OPEN_IN_APP_STORAGE_KEY = 'openInapp';

export const VALUE_OPEN_IN_APP_FOR_GUEST = 'for_guest';

export const getOpenInApp = () => localStorage.getItem(OPEN_IN_APP_STORAGE_KEY);

export const isOpenInapp = () => {
  const openInapp = getOpenInApp();

  const params = new URLSearchParams(window.location.search);
  const urlOpenInapp = params.get('openInapp') === '1';

  if (urlOpenInapp || openInapp) {
    return true;
  }

  const inapp = new InApp(
    navigator.userAgent || navigator.vendor || window.opera,
  );
  const isInApp = lodashGet(inapp, 'isInApp');
  return typeof isInApp === 'function' ? isInApp() : false;
};

export const setOpenInApp = (value) =>
  localStorage.setItem(OPEN_IN_APP_STORAGE_KEY, value);

export const clearOpenInApp = () =>
  localStorage.removeItem(OPEN_IN_APP_STORAGE_KEY);

export const valueToSetOpenInApp = (user) => {
  const token = lodashGet(user, 'token');
  const iid = lodashGet(user, 'iid');

  if (!token || !iid) {
    return VALUE_OPEN_IN_APP_FOR_GUEST;
  }

  return `${iid}_with_${token}`;
};
